import './activationguide.css';
import React, { Component } from 'react';
import { getCustomerState, focusOnHeading, getCurrentRouteName, scrollToBody, getBrandDetails, applyValuesToTemplate, applyBrandDataInTemplate, getAbleSpriteSheet, getIsDebitCard } from '../../adapters/utils';
import { getUiState } from '../../adapters/store-utils';
import { addDataLayerEventInfo, addToTransactionLayer } from '../../adapters/analytics-utils';
import myTelstraApp from '../../assets/img/mytelstrapromo.png';
import tickHeader from '../../assets/img/svg/Sucess104.svg';
import simCard from '../../assets/img/svg/SIM104.svg';
import telstraIcon from '../../assets/img/svg/Telstra56.svg';
import telstraAppIcon from '../../assets/img/svg/MyTelstra56.svg';
import OfferCard from './../../routes/review-details/components/offerCard';
import esimImage from '../../assets/img/esim-Image.svg';
import store from '../../store';
import { GET_THE_APP, DOWNLOAD_APP_IOS, DOWNLOAD_APP_ANDROID, SIGN_IN, BRANDS } from '../../adapters/constants';
import { updateStepperCustomData } from '../../components/Progress/module';
import AutoRechargeEnabledCard from './AutoRechargeEnabled/autoRechargeEnabled';
import ACTIVATION_GUIDE_LOCALE from '../../assets/locale/activation-guide-locale';
import SpinningButton from '../../components/SpinningButton/SpinningButton';
import GlobalBanner from '../../common/components/globalBanner/globalBanner';
import { getEsimProfileDownloadDeeplink } from '../../adapters/esim-utils';
import { ActionButton, MessageSection, Spacing, TextStyle, Icon, Divider } from '@able/react';

const step2image = require('../../assets/img/Step2-image.jpg');
const step3image = require('../../assets/img/Step3-image.jpg');
const ableSpriteSheet = getAbleSpriteSheet();

class ActivationGuide extends Component {
    constructor(props) {
        super(props);
        this.profileDownloadRedirect = this.profileDownloadRedirect.bind(this);
    }

    componentDidMount() {
        const state = store.getState();
        const {
            customer: { data: { hasTdiLogin = true } = {} } = {},
            esimData: { hasCompletedEsimProfileDownload }
        } = state.app.appData;
        const {
            activationDataDetails: { customerInteractionId }
        } = state.app.appData.activationData;
        if (!hasTdiLogin) {
            addDataLayerEventInfo('impression', 'createTDI');
        } else {
            addDataLayerEventInfo('impression', 'existingTDI');
        }
        const skipRecharge = this.props.appData.skipRecharge;
        if (skipRecharge === false) {
            addDataLayerEventInfo('prepaidRecharge', '', '', '');
        }
        const pageNameArr = getCurrentRouteName();
        document.title = pageNameArr.pageName;
        focusOnHeading();
        // const { rechargeAmount, offerCode } = getCustomerState();
        // const data = {
        //     productID: offerCode,
        //     price: rechargeAmount,
        //     quantity: 1
        // };
        scrollToBody();
        addToTransactionLayer(customerInteractionId);
        const { isEsimActivation = false } = getUiState();
        const { downloadEsimProfile } = this.props.actions;
        isEsimActivation && !hasCompletedEsimProfileDownload && downloadEsimProfile();
        const stepperData = {
            totalSteps: isEsimActivation ? 7 : 6,
            currentStep: isEsimActivation ? 7 : 5,
            isLastStepCompleted: true
        };
        store.dispatch(updateStepperCustomData(stepperData));
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', () => {
            window.history.pushState(null, document.title, window.location.href);
        });
    }

    render() {
        const { selectedActivationFlow = '', loggedIn = false, isEsimActivation = false } = getUiState();
        const isPortingFlow = selectedActivationFlow === 'portNumber';
        const skipRecharge = this.props.appData.skipRecharge;
        const hasEsimProfileError = this.props.appData.esimData.hasEsimProfileError;
        const isProcessingProfile = this.props.appData.esimData.isProcessingProfile;
        const serviceCount = this.props.appData.customerInfo.serviceCount;
        const businessDetails = this.props.app.loggedInCustomerData && this.props.app.loggedInCustomerData.businessDetails;
        const selectedBusinessDetails = (businessDetails && businessDetails.find((item) => item.accountNumber === this.props.selectedBusiness)) || {};

        // Get Service details
        const state = store.getState();
        let actualAmount;
        let priceBeforeDiscount;
        // hasTdiLogin is true when Unauthenticated customer is recognised as having existing TDI
        const { customer: { data: { hasTdiLogin = true } = {} } = {}, updateCart: { data: { cartTotalPrice = [] } = {} } = {}, msisdn } = state.app.appData;
        if (cartTotalPrice.length !== 0) {
            const { price: { dutyFreeAmount: { value = '' } = {} } = {}, priceBeforeDiscount: { dutyFreeAmount: { value: priceBeforeDiscountVal = '' } = {} } = {} } = cartTotalPrice[0];
            actualAmount = value;
            priceBeforeDiscount = priceBeforeDiscountVal;
        }
        const newOfferSelected = state.offerDetails.selectedOffer ? state.offerDetails.selectedOffer : {};
        const { yourContactDetailsForm: { values: { emailAddress: userEmail } = {} } = {} } = state.form || {};
        const { portingNumber = '' } = state.welcome;
        const { starterCredit } = getCustomerState();
        const { activationDataDetails: { isAutoRechargeSuccessful } = {} } = state.reviewDetails.activationData || {};
        const { surcharge = [], isDebitCardFromSurcharge, isSurchargeAPIDown, isDebitCardFromBRNTreeORSavedCard } = state.payment;
        const isDebitCard = isDebitCardFromSurcharge || isDebitCardFromBRNTreeORSavedCard;
        // Get confirmation page locale
        const {
            title: { esimTitle, activationTitle },
            porting: { esimPorting, physicalSimPorting, portingSimHeader, portingSimContent },
            getNewNumber: { esimTitleMessage, numTitleMessage, numSimHeader, numSimContent }
        } = ACTIVATION_GUIDE_LOCALE;

        // Dynamic contents for esim 'porting/getNewNumber' and Physical sim 'porting/getNewNumber'
        const serviceNumber = isPortingFlow ? portingNumber : msisdn;
        let titleMessage;
        let simHeader;
        let simContent;
        const ConfirmHeader = isEsimActivation ? esimTitle : activationTitle;
        const esimClass = isEsimActivation ? 'esim-style' : '';
        if (selectedActivationFlow === 'portNumber') {
            if (isEsimActivation) {
                titleMessage = esimPorting;
            } else {
                titleMessage = physicalSimPorting;
                simHeader = portingSimHeader;
                simContent = portingSimContent;
            }
        } else if (isEsimActivation) {
            titleMessage = esimTitleMessage;
        } else {
            titleMessage = numTitleMessage;
            simHeader = numSimHeader;
            simContent = numSimContent;
        }

        const titleMessageBrandDetails = getBrandDetails();
        if (titleMessageBrandDetails.brandName === BRANDS.Telstra.name) {
            titleMessageBrandDetails.brandName += ' network';
        }
        titleMessage = applyValuesToTemplate(titleMessage, titleMessageBrandDetails);
        simHeader = applyBrandDataInTemplate(simHeader);
        simContent = applyBrandDataInTemplate(simContent);

        const autoRechargeCardProps = {
            loggedIn,
            hasTdiLogin,
            newOfferSelected,
            actualAmount,
            priceBeforeDiscount,
            starterCredit,
            surcharge,
            isSurchargeAPIDown,
            isDebitCard
        };

        if (isAutoRechargeSuccessful === false) {
            addDataLayerEventInfo('error', 'auto recharge module', '', 'Auto recharge setup unsuccessful');
            if (!hasTdiLogin) {
                addDataLayerEventInfo('impression', 'auto recharge module', 'fail', 'Auto recharge setup unsuccessful - New TDI');
            } else {
                addDataLayerEventInfo('impression', 'auto recharge module', 'fail', 'Auto recharge setup unsuccessful - Existing TDI');
            }
        }

        return (
            <div className="container confirm-block">
                <GlobalBanner onPage="success" className="infobanner-success" />
                <div className="row">
                    {isEsimActivation ? (
                        isProcessingProfile ? null : !hasEsimProfileError ? (
                            <>
                                <div className="col-md-6">
                                    <TextStyle element="h1" alias="HeadingA" tabIndex="-1" className={`confirm-block-header ${esimClass}`}>
                                        {ConfirmHeader}
                                    </TextStyle>
                                    <TextStyle alias="TextBodyShort" className="mb-4 mr-5">{titleMessage}</TextStyle>
                                    <SpinningButton id="Download" stylingClass="esim-download" variant="HighEmphasis" buttonText={'Download'} onSubmit={() => this.profileDownloadRedirect()} />
                                </div>
                                <>{this.renderEsimIllustration()}</>
                            </>
                        ) : (
                            this.renderProfileErrorGuide(serviceNumber, starterCredit, actualAmount, newOfferSelected, skipRecharge)
                        )
                    ) : (
                        <div className="col-md-7">
                            <img className="tick-header" src={tickHeader} alt="tick" />
                            <TextStyle element="h1" alias="HeadingA" tabIndex="-1" className={`confirm-block-header ${esimClass}`}>
                                {ConfirmHeader}
                            </TextStyle>
                            <TextStyle alias="TextBodyShort" className="mb-4">{titleMessage}</TextStyle>
                        </div>
                    )}
                </div>
                {((!loggedIn && serviceCount === 34) || (loggedIn && selectedBusinessDetails.lastService)) && (
                    <div className="row max-limit-reached-alert">
                        <div className="col-md-6">
                            <MessageSection
                                className="max-limit-info-bar"
                                developmentUrl={ableSpriteSheet}
                                variant="Information"
                                description="You've almost reached the maximum number of Pre-Paid services on your account. This will be the last service you can activate." />
                        </div>
                    </div>
                )}
                <div className="row">
                    {/* <div className="col-md-6">
                        {this.getCardDetails(serviceNumber, starterCredit, actualAmount, newOfferSelected, skipRecharge)}
                    </div> */}

                    {isEsimActivation
                        ? isProcessingProfile
                            ? null
                            : !hasEsimProfileError
                                ? this.renderAutoRechargeEnabledCardAndOthers(
                                    serviceNumber,
                                    starterCredit,
                                    actualAmount,
                                    newOfferSelected,
                                    skipRecharge,
                                    isAutoRechargeSuccessful,
                                    autoRechargeCardProps,
                                    isEsimActivation,
                                    loggedIn,
                                    hasTdiLogin,
                                    simHeader,
                                    simContent,
                                    userEmail
                                )
                                : null
                        : this.renderAutoRechargeEnabledCardAndOthers(
                            serviceNumber,
                            starterCredit,
                            actualAmount,
                            newOfferSelected,
                            skipRecharge,
                            isAutoRechargeSuccessful,
                            autoRechargeCardProps,
                            isEsimActivation,
                            loggedIn,
                            hasTdiLogin,
                            simHeader,
                            simContent,
                            userEmail
                        )}

                    {!isEsimActivation && this.renderDownloadContent()}
                </div>

                <div className="row">
                    {isEsimActivation ? (
                        isProcessingProfile ? null : !hasEsimProfileError ? (
                            <div className="col-md-6">{this.renderDirectoryListing()}</div>
                        ) : null
                    ) : (
                        <div className="col-md-6">{this.renderDirectoryListing()}</div>
                    )}
                </div>
            </div>
        );
    }

    renderEsimIllustration() {
        return (
            <div className="col-md-6 div-image">
                <img
                    className="esim-image img-fluid hide-in-mobile"
                    alt={''}
                    /* eslint-disable global-require */
                    src={esimImage}
                /* eslint-enable global-require */
                />
            </div>
        );
    }

    renderAutoRechargeEnabledCardAndOthers(
        serviceNumber,
        starterCredit,
        actualAmount,
        newOfferSelected,
        skipRecharge,
        isAutoRechargeSuccessful,
        autoRechargeCardProps,
        isEsimActivation,
        loggedIn,
        hasTdiLogin,
        simHeader,
        simContent,
        userEmail
    ) {
        return (
            <div className="col-md-6">
                {this.getCardDetails(serviceNumber, starterCredit, actualAmount, newOfferSelected, skipRecharge)}

                {isAutoRechargeSuccessful && <AutoRechargeEnabledCard {...autoRechargeCardProps} />}
                {isAutoRechargeSuccessful === false && (
                    <MessageSection
                        className="mbxxl ar-failed-alert"
                        variant="Error"
                        developmentUrl={ableSpriteSheet}
                        titleText="Auto recharge setup unsuccessful"
                        description="Please try again later on the My Telstra app." />
                )}
                {!isEsimActivation && (loggedIn || hasTdiLogin) ? this.renderRegistrationContent(simHeader, simContent) : !isEsimActivation && this.renderUnauthContent(simHeader, simContent, userEmail)}
            </div>
        );
    }

    getCardDetails(serviceNumber, starterCredit, actualAmount, newOfferSelected, skipRecharge) {
        return <OfferCard {...this.props} plan={newOfferSelected} msisdn={serviceNumber} starterCredit={starterCredit} price={actualAmount} confirm={'true'} skipRecharge={skipRecharge} />;
    }

    renderRegistrationContent(simHeader, simContent) {
        return (
            <div>
                <TextStyle element="h2" alias="HeadingB" className="mb4xl">Next steps</TextStyle>
                <img className="mbl" src={simCard} alt="" />
                <TextStyle element="h3" alias="HeadingC" className="mbxl">{simHeader}</TextStyle>
                <TextStyle element="p" alias="TextBodyShort" className="mb-5">{simContent}</TextStyle>
                <img className="mbl" src={telstraIcon} alt="" />
                <TextStyle element="h3" alias="HeadingC" className="mbxl">2. Manage your service online</TextStyle>
                <TextStyle element="p" alias="TextBodyShort" className="my-3">Use your Telstra ID to manage your service online.</TextStyle>
                <ActionButton element="a" href={SIGN_IN} label="Sign in" variant="LowEmphasis" />
            </div>
        );
    }

    renderUnauthContent(simHeader, simContent, userEmail) {
        const detailMessage = '. We’ve just sent you a verification email so you can activate it. You can then access your account and manage your payment preferences.';
        return (
            <div>
                <TextStyle element="h2" alias="HeadingC" className="mbl">Next steps</TextStyle>
                <img className="mbl" src={simCard} alt="" />
                <TextStyle element="h3" alias="HeadingD" className="mbm">{simHeader}</TextStyle>
                <TextStyle element="p" alias="TextBodyShort" className="my-3">{simContent}</TextStyle>
                <img className="mbl" src={telstraIcon} alt="" />
                <TextStyle element="h3" alias="HeadingD" className="mbm">2. Verify your Telstra ID</TextStyle>
                <TextStyle element="p" alias="TextBodyShort" className="my-3">
                    {'Your Telstra ID is '}
                    {userEmail}
                    {detailMessage}
                </TextStyle>
            </div>
        );
    }

    renderDownloadContent() {
        let downloadUrl = '';
        let downloadButton = '';
        if (window.navigator.userAgent.indexOf('Mac') !== -1 && window.navigator.userAgent.indexOf('Mobile') !== -1) {
            downloadButton = 'Download';
            downloadUrl = DOWNLOAD_APP_IOS;
        } else if (window.navigator.userAgent.indexOf('Android') !== -1 && window.navigator.userAgent.indexOf('Mobile') !== -1) {
            downloadButton = 'Download';
            downloadUrl = DOWNLOAD_APP_ANDROID;
        } else {
            downloadButton = 'Download app';
            downloadUrl = GET_THE_APP;
        }
        const brandDetails = getBrandDetails();
        return (
            <div className="col-md-4 col-xs-12 download-card">
                <div>
                    <img className="my-telstra-image" src={myTelstraApp} alt="" />
                </div>
                <div className="download-content">
                    <div className="download-app">
                        <TextStyle className="heading" element={'h1'} alias="HeadingC">
                            Download the My Telstra app
                        </TextStyle>
                        <img className="my-t-app" src={telstraAppIcon} alt="" />
                    </div>
                    <Divider />
                    <TextStyle alias="TextBodyShort" className="manage-text-style">
                        Manage your services in one place with the My Telstra app
                    </TextStyle>
                    <div role="list">
                        <TextStyle element="p" role="listitem" alias="Label" className="mbm tick-text">
                            <span>
                                <Icon icon="Tick" aria-hidden developmentUrl={ableSpriteSheet} />
                            </span>
                            Manage your payments
                        </TextStyle>
                        <TextStyle element="p" role="listitem" alias="Label" className="mbm tick-text">
                            <span>
                                <Icon icon="Tick" aria-hidden developmentUrl={ableSpriteSheet} />
                            </span>
                            Recharge on the go
                        </TextStyle>
                        {brandDetails.brandName === BRANDS.Telstra.name && (
                            <TextStyle element="p" role="listitem" alias="Label" className="mbm tick-text">
                                <span>
                                    <Icon icon="Tick" aria-hidden developmentUrl={ableSpriteSheet} />
                                </span>
                                <span className="earn-points">Earn points and rewards with Telstra Plus</span>
                            </TextStyle>
                        )}
                    </div>
                    <ActionButton
                        variant="MediumEmphasis"
                        label={downloadButton}
                        element="a"
                        href={downloadUrl}
                        className="w-100 download-app-btn mt-3" />
                </div>
            </div>
        );
    }

    renderDirectoryListing() {
        const {
            directoryListing: { header, content, contactUsMessage }
        } = ACTIVATION_GUIDE_LOCALE;
        const newTabForRechargeInfo = () => {
            const href = 'https://www.telstra.com.au/support/category/home-phone/what-is-our-unlisted-service-option';
            window.open(href, '_blank');
        };
        return (
            <div id="directory-listing">
                <MessageSection
                    developmentUrl={ableSpriteSheet}
                    variant="Information"
                    description={content}
                    titleText={header}
                    titleElement="h2"
                    actionId="directory-list"
                    actionCallback={newTabForRechargeInfo}
                    actionElement="button"
                    actionText={contactUsMessage}
                />
            </div>
        );
    }

    profileDownloadRedirect() {
        addDataLayerEventInfo('click', 'clickTrack', 'buttonClick', 'eSIM download');
        const state = store.getState();
        const {
            esimData: { activationCode },
            msisdn
        } = state.app.appData;
        const regex = /^\+61|^61/;
        const serviceId = msisdn.replace(regex, 0);
        const redirectUrl = getEsimProfileDownloadDeeplink({ activationCode, serviceId });
        window.localStorage.removeItem('source');
        window.localStorage.removeItem('esimFlow');
        window.location.href = redirectUrl;
    }

    Capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    renderProfileErrorGuide(serviceNumber, starterCredit, actualAmount, newOfferSelected, skipRecharge) {
        const {
            esimProfileError: {
                esimProfileErrortitle,
                esimProfileErrorTitleMessage,
                step1,
                step1Message,
                step2,
                step2Message,
                step3,
                step3Message,
                step4,
                step4Message,
                step5,
                step5Message,
                step6,
                step6Message
            }
        } = ACTIVATION_GUIDE_LOCALE;
        return (
            <div className="row m-0 esim-profile-error-guide w-100">
                <div className="col-md-6 col-sm-12">
                    <div className="error-guide-section">
                        <Spacing bottom="spacing5x" topVLG="spacing2x" topVMD="spacing2x" topVSM="spacing2x">
                            <TextStyle element="h1" alias="HeadingA">
                                {esimProfileErrortitle}
                            </TextStyle>
                        </Spacing>
                        <Spacing bottom="spacing4x">
                            <TextStyle alias="TextBodyShort">{esimProfileErrorTitleMessage}</TextStyle>
                        </Spacing>

                        <TextStyle alias="HeadingC">{step1}</TextStyle>
                        <Spacing top="spacing1x" bottom="spacing4x">
                            <TextStyle alias="TextBodyShort">{step1Message}</TextStyle>
                        </Spacing>

                        <TextStyle alias="HeadingC">{step2}</TextStyle>
                        <Spacing top="spacing1x" bottom="spacing2x">
                            <TextStyle alias="TextBodyShort">{step2Message}</TextStyle>
                        </Spacing>
                        <Spacing bottom="spacing4x">
                            <div className="esim-error-step-image">
                                <img alt="" src={step2image} />
                            </div>
                        </Spacing>

                        <TextStyle alias="HeadingC">{step3}</TextStyle>
                        <Spacing top="spacing1x" bottom="spacing2x">
                            <TextStyle alias="TextBodyShort">{step3Message}</TextStyle>
                        </Spacing>
                        <Spacing bottom="spacing4x">
                            <div className="esim-error-step-image">
                                <img alt="" src={step3image} />
                            </div>
                        </Spacing>

                        <TextStyle alias="HeadingC">{step4}</TextStyle>
                        <Spacing top="spacing1x" bottom="spacing4x">
                            <TextStyle alias="TextBodyShort">{step4Message}</TextStyle>
                        </Spacing>

                        <TextStyle alias="HeadingC">{step5}</TextStyle>
                        <Spacing top="spacing1x" bottom="spacing4x">
                            <TextStyle alias="TextBodyShort">{step5Message}</TextStyle>
                        </Spacing>

                        <TextStyle alias="HeadingC">{step6}</TextStyle>
                        <Spacing top="spacing1x" bottom="spacing15x" bottomVXS="spacing7x">
                            <TextStyle alias="TextBodyShort">{step6Message}</TextStyle>
                        </Spacing>
                    </div>
                </div>

                <div className="col-md-6 col-sm-12">
                    <div className="error-guide-section error-guide-section--right">
                        <Spacing topVXS="spacing3x" bottom="spacing4x" bottomVXS="spacing5x" topVLG="spacing2x" topVMD="spacing2x" topVSM="spacing2x">
                            <TextStyle alias="HeadingC">Recharge details</TextStyle>
                        </Spacing>
                        {this.getCardDetails(serviceNumber, starterCredit, actualAmount, newOfferSelected, skipRecharge)}

                        <Spacing top="spacing4x" topVXS="spacing5x" bottom="spacing15x">
                            {this.renderDirectoryListing()}
                        </Spacing>
                    </div>
                </div>
            </div>
        );
    }
}
export default ActivationGuide;
