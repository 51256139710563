const API_ENDPOINTS_CONSTANTS = {

  endpoints: {
    packageDetails: "v1/packages/",
    portingEligibility: "v1/packages/porting",
    OfferDetails: "v1/offers/",
    userInfo: "v1/user/",
    customerValidation: "v1/customers",
    documentValidation: "v1/customers/document",
    serviceActivation: "v1/packages/activate",
    currentCustomer: "v1/customers/CURRENT",
    createCart: "v1/shoppingcart/createcart",
    braintreeToken: "v1/payment/token",
    updateCartData: "v1/shoppingcart/submitcart/cart/",
    idvMagicUrl: "v1/idv",
    validatedIdvData: "v1/idv/details",
    preport: "v1/numberportability/msisdn",
    verifyCode: "v1/ppv/verify-code",
    saveAutoRechargeforValueSim: "v1/payment/wallet",
    sendOTC: "v1/otc/send",
    verifyOTC: "v1/otc/verify",
    updateContact: "v1/contactmedia",
    verifyABNOrACN: "v1/customers/business",
    createSMBAccount: "v1/customers/business/abn",
    payAndActivateSim: "v1/payandactivate",
    fetchCart: "v1/shoppingcart/submitcart/",
    fetchOffers: "v1/rechargePlans",
    initaitePaypal: "v1/payments/payment",
    billPay: "v1/billpay/payments/payment",
    visaCheckout: "v1/billpay/payments/visacheckout",
    registerTelstraId: "v1/user-registration/users",
    adhocPayBraintree: "v1/payment/adhoc",
    fetchSavedCardList: "v1/payment/savedCard",
    fetchTelstraIdDetails: "v1/wifi/msisdn-email-validation",
    retrieveParams: "/esim/retrieve-parameters",
    requestMsisdn: "/esim/request-msisdn",
    reserveMsisdn: "/esim/reservation",
    esimProfileDownload: "/esim/profile",
    esimEligibility: "/esim/eligibility",
    addressDetails: "v1/addressautocomplete/search",
    addressVerification: "v1/validation/simplifiedstructure",
    surchargeRates:"v1/payment/surcharge",
    surchargeTableData: "v1/payment/surchargerates"
  },
};

export default API_ENDPOINTS_CONSTANTS;
